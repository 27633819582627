<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="warehouseData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching warehouse data
      </h4>
      <div class="alert-body">
        No warehouse found with this warehouse id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-warehouse-list'}"
        >
          Warehouse List
        </b-link>
        for other warehouses.
      </div>
    </b-alert>

    <div v-if="warehouseData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          md="12"
        >
          <info-card
            :station-data="warehouseData"
            :role-data="productData"
          />
        </b-col>
        <!-- <b-col
          cols="12"
          xl="2"
          md="4"
        >
          <b-row>
            <b-col
              md="12"
            >
              <statistic-card-vertical
                color="success"
                icon="BatteryChargingIcon"
                :statistic="`${usingbats}/${totalProducts}`"
                statistic-title="Используется"
              />
            </b-col>
            <b-col
              md="12"
            >
              <b-card>

                <b-row>
                  <b-col
                    cols="12"
                    xl="12"
                    class="d-flex justify-content-between flex-column"
                  >
                    <div>
                      <div>
                        <h6>
                          Громкость устройство:
                        </h6>
                        <template>
                          <div>
                            <b-form-group>
                              <b-input-group>
                                <b-form-input
                                  id="volume"
                                  v-model="warehouseData.signal"
                                  type="range"
                                  number
                                  min="1"
                                  max="15"
                                />
                                <b-input-group-append
                                  is-text
                                  class="text-monospace"
                                >
                                  {{ warehouseData.signal }}
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                            <b-alert
                              class="mt-1 mb-0"
                              :show="dismissCountDown"
                              dismissible
                              :variant="alertType.type"
                              @dismissed="dismissCountDown=0"
                              @dismiss-count-down="countDownChanged"
                            >
                              <div class="alert-body">
                                <span>{{ alertType.text }}</span>
                              </div>
                            </b-alert>
                          </div>
                        </template>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <battery-list
            :station-data="warehouseData"
            :battery-data="productData"
            :total-batteries="totalProducts"
            @refetch-data="init"
          />
        </b-col>
      </b-row>

    </div>
    <div
      v-else
      class="text-center text-primary my-2"
    >
      <b-spinner
        class="align-middle mr-1"
      />
      <strong>Загрузка...</strong>
    </div>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BSpinner,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import warehouseStoreModule from '../warehouseStoreModule'
import InfoCard from './InfoCard.vue'
import BatteryList from './WarehouseList.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BSpinner,

    // Local Components
    InfoCard,
    BatteryList,

  },
  setup() {
    const usingbats = ref(0)
    const toast = useToast()
    const warehouseData = ref(null)
    const productData = ref([])

    const WAREHOUSE_APP_STORE_MODULE_NAME = 'app-warehouse'

    // Register module
    if (!store.hasModule(WAREHOUSE_APP_STORE_MODULE_NAME)) store.registerModule(WAREHOUSE_APP_STORE_MODULE_NAME, warehouseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WAREHOUSE_APP_STORE_MODULE_NAME)) store.unregisterModule(WAREHOUSE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-warehouse/fetchWarehouses', {
      _join: 'wm.Warehouses,_lj,u0.Users',
      _on1: '(wm.resp_user_id,eq,u0.id)',
      _where: `(wm.id,eq,${router.currentRoute.params.id})`,
      _fields: 'wm.id,wm.name,u0.email,wm.city,wm.address,wm.type,wm.comments,wm.resp_user_id,u0.firstName,u0.lastName',
      _draw: 1,
      _tableconstruction_version: 'vertri',
      _zeTable: 'Warehouses',
    })
      .then(response => {
        const obj = response.data.data[0]
        warehouseData.value = obj
      })
      .catch(error => {
        if (error.response.status === 404) {
          warehouseData.value = undefined
        }
      })
    const alertType = ref({
      type: 'success',
      text: 'Громкость устройства изменено успешно.',
    })
    const dismissCountDown = ref(0)
    const countDownChanged = val => {
      dismissCountDown.value = val
    }

    const totalProducts = ref(0)
    const init = () => {
      store
        .dispatch('app-warehouse/getProductsbyWm', {
          product_id: router.currentRoute.params.id,
        })
        .then(response => {
          const { data } = response
          totalProducts.value = data.length
          productData.value = data
          // const arr = data.filter(item => item.status === 'RENTED')
          // usingbats.value = arr.length
        })
        .catch(err => {
          console.log(err)
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching batteries' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    init()
    // store.dispatch('app-user/checkRole', {
    //   _where: `(user_id,eq,${router.currentRoute.params.id})`,
    //   _fields: 'group_id',
    //   _tableconstruction_version: 'vertri',
    //   _zeTable: 'UsersGroups',
    // })
    //   .then(roles => {
    //     const rolesArr = []
    //     for (let i = 0; i < roles.data.records.length; i += 1) {
    //       rolesArr.push(roles.data.records[i].group_id)
    //     }
    //     // console.log(rolesArr)
    //     return rolesArr
    //   })
    //   .then(async rolesArr => store.dispatch('app-user/fetchRoles', {
    //     _where: `(id,eq,${rolesArr.join(',')})`,
    //     _fields: 'name,id',
    //     _tableconstruction_version: 'vertri',
    //     _zeTable: 'Groups',
    //   }))
    //   .then(rolesNames => {
    //     const result = []
    //     for (let i = 0; i < rolesNames.data.records.length; i += 1) {
    //       const role = rolesNames.data.records[i]
    //       result.push({ id: role.id, name: role.name })
    //     }
    //     // console.log(result)
    //     productData.value = result
    //   })
    //   .catch(error => {
    //     if (error) {
    //       console.log(error)
    //       productData.value = undefined
    //     }
    //   })
    return {
      warehouseData,
      productData,
      totalProducts,
      alertType,
      dismissCountDown,
      countDownChanged,
      usingbats,
      init,
    }
  },
}
</script>

<style>

</style>
