import axios from '@axios'

export default {
  namespaced: true,
  state: {
    skladSums: [],
    activeSklad: 0,
    dateRange: [],
    dateRange2: []
  },
  getters: {},
  mutations: {
    UPDATE_SKLAD_SUMMARY(state, val) {
      state.skladSums = val
    },
    UPDATE_ACTIVE_SKLAD(state, val) {
      state.activeSklad = state.activeSklad === val ? null : val
    },
    UPDATE_RANGE(state, val) {
      state.dateRange = val
    },
    UPDATE_RANGE2(state, val) {
      state.dateRange2 = val
    }
  },
  actions: {
    fetchWmManagementView(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/WmManagement_view', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWarehouses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/xjoin', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchXjoin(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/xjoin', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProductsbyWm(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getProductsbyWm', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWarehouse(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Warehouses/', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addWarehouse(ctx, warehouseData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Warehouses/', warehouseData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateWarehouse(ctx, { id, warehouseData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/updateRow/Warehouses/${id}`, warehouseData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteWarehouse(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteRow/Warehouses/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteWhProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteRow/WmProducts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers() {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Users', {
            _size: 1000,
            _p: 1,
            _sort: '',
            _where: '',
            _fields: '-',
            _tableconstruction_version: 'vertri',
            _zeTable: 'Users'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  }
}
